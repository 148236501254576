<template>
  <div class="container">
    <h1 style="text-align:center;">שינוי פרטי חשבון בנק</h1>
    <div class="warpper">
      <div class="field">
        <p>שם מלא של העובד</p>
        <InputText
          v-model="values.fullName"
          type="text"
          placeholder="שם מלא"
          style="width:100%"
        />
      </div>
      <div class="field">
        <p>מס' ת"ז</p>
        <InputText
          v-model="values.idNumber"
          type="text"
          placeholder="מס' זהות"
          style="width:100%"
        />
      </div>
      <div class="field">
        <p>שם פותח/ת הבקשה</p>
        <InputText
          v-model="values.ownerName"
          type="text"
          placeholder="הזן/י את שמך"
          style="width:100%"
        />
      </div>
      <div class="field">
        <p>דוא"ל לעדכון התהליך</p>
        <InputText
          v-model="values.email"
          type="text"
          placeholder="דואל"
          style="width:100%"
        />
      </div>
      <div class="field">
        <p>שם בנק</p>
        <Dropdown
          v-model="values.bankName"
          :options="banksOptions"
          placeholder="שם בנק"
        />
      </div>
      <div class="field">
        <p>מס' סניף</p>
        <InputNumber v-model="values.brancheBankNumber" />
      </div>
      <div class="field">
        <p>מס' חשבון</p>
        <InputNumber
          id="withoutgrouping"
          v-model="values.bankAccount"
          mode="decimal"
          :useGrouping="false"
        />
      </div>
      <div class="field">
        <p style="color:red;">מטופל ע"י</p>
        <Dropdown
          :options="chargeOptions"
          v-model="values.charge"
          placeholder="מטופל על ידי"
          style="width:100%"
        />
      </div>
      <div class="field">
        <p style="color:red;">סטטוס</p>
        <Dropdown
          :options="statusOptions"
          v-model="values.status"
          placeholder="סטטוס טיפול"
          style="width:100%"
        />
      </div>
      <div class="field">
        <p style="color:red;">הודעה לסניף</p>
        <Textarea
          v-model="values.msg"
          rows="5"
          cols="30"
          placeholder="הודעה זו תשלח בגוף המייל לפותח הבקשה"
        />
      </div>
      <div class="field">
        <p>נא לצרף צילום כ.א/שיק/אישור חשבון בנק</p>
        <p>
          באפשרותך ניתן להוסיף תמונה או מסמך PDF במידת הצורך - ניתן להוסיף עד 3
          קבצים
        </p>
        <Button
          v-if="!isPending"
          label="צירף תמונה/מסמך"
          class="p-button-secondary upload-btn"
          @click="handleUpload"
        />
        <Button
          v-if="isPending"
          label="ממתין להעלאה..."
          class="p-button-secondary upload-btn"
          disabled
        />
        <input
          v-show="false"
          id="upload-image"
          type="file"
          @change="handleChange"
        />
        <div v-if="fileError" style="color:red">{{ fileError }}</div>
      </div>
      <div class="field">
        <div class="images">
          <template
            v-for="document in values.uploadedDocuments"
            :key="document.filePath"
          >
            <div class="image">
              <img
                v-if="document.type != 'application/pdf'"
                :src="document.url"
                @click="handleOpenPdf(document)"
              />
              <img
                v-if="document.type == 'application/pdf'"
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png"
                @click="handleOpenPdf(document)"
              />
              <div class="delete-bar" @click="delete_file(document)">
                <p>מחק</p>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <Button
      label="עדכן"
      class="p-button-success send-btn"
      @click="handleSubmit"
    />
    <Loader v-if="isPending" />
  </div>

  <!-- JUST FOR MAIL -->
  <div class="form-email" id="form-email" v-show="false">
    <table
      style="width:50%; border-collapse: collapse; font-size: 1.1rem; margin: 1rem 0;"
    >
      <tr style="background-color: #dddddd;">
        <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
          תאריך הבקשה
        </th>
        <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
          {{ values.createdAt }}
        </td>
      </tr>
      <tr>
        <th style="border:1px solid #dddddd; text-align:right; padding:8px;">
          שם הסניף השולח
        </th>
        <td style="border:1px solid #dddddd; text-align:right; padding:8px;">
          {{ values.branche }}
        </td>
      </tr>
      <tr style="background-color: #dddddd;">
        <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
          סוג הבקשה
        </th>
        <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
          {{ values.type }}
        </td>
      </tr>
      <tr>
        <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
          שם העובד/ת
        </th>
        <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
          {{ values.fullName }}
        </td>
      </tr>
      <tr style="background-color: #dddddd;">
        <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
          מס' ת"ז של העובד/ת
        </th>
        <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
          {{ values.idNumber }}
        </td>
      </tr>
      <tr>
        <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
          שם פותח הבקשה
        </th>
        <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
          {{ values.ownerName }}
        </td>
      </tr>
      <tr style="background-color: #dddddd;">
        <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
          אימייל לעדכון סטטוס וקבלת עדכונים
        </th>
        <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
          {{ values.email }}
        </td>
      </tr>
      <tr>
        <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
          שם הבנק
        </th>
        <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
          {{ values.bankName }}
        </td>
      </tr>
      <tr style="background-color: #dddddd;">
        <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
          מס' סניף
        </th>
        <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
          {{ values.brancheBankNumber }}
        </td>
      </tr>
      <tr>
        <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
          מס' חשבון
        </th>
        <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
          {{ values.bankAccount }}
        </td>
      </tr>
      <tr style="background-color: #dddddd;">
        <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
          סטטוס
        </th>
        <td
          style="border:1px solid #dddddd; text-align:right; padding: 8px; color:green"
        >
          {{ values.status }}
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
/* eslint-disable */
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import InputNumber from "primevue/inputnumber";
import Textarea from "primevue/textarea";
import swal from "sweetalert";
import {projectFirestore,projectFunctions} from '@/firebase/config'
import use_office_requests_storage from '@/Methods/use_office_requests_storage'
import { onMounted, ref } from "vue";
import {update_status_counter,convert_type_he_to_en} from '@/Methods/office_request_funcs'
import Loader from '../loader/Loader.vue'
import router from '../../../../../../router';
export default {
  components: { InputText, InputNumber, Textarea, Dropdown,Loader },
  props:['docId','data'],
  setup(props) {
    const { url, filePath, uploadImage, deleteImage } = use_office_requests_storage();
    const chargeOptions=ref([
      'שירז',
      'אירה',
      'עדי'
    ])
    const statusOptions=ref([
      'חדש',
      'ממתין',
      'טופל'
    ])
    const banksOptions = ref([
      "בנק לאומי 10",
      "בנק הפועלים 12",
      "בנק מזרחי 20",
      "בנק דיסקונט 11",
      "בנק מרכנתיל דיסקונט 17",
      "בנק מרכנתיל דיסקונט לשעבר מוניציפל/דקסיה 68",
      "בנק הבינלאומי 31",
      "בנק מסד 46",
      "בנק הבינלאומי לשעבר בנק אוצר החייל 14",
      "בנק הבינלאומי לשעבר בנק פועלי אגודת ישראל (פאגי) 52",
      "בנק איגוד 13",
      "בנק יהב 4",
      "בנק הדואר 9",
      "בנק ירושלים 54",
      "הבנק הדיגיטלי הראשון 18",
      "סיטי בנק 22"
    ]);
    const isPending = ref(false);
    const error = ref("");
    const file = ref(null);
    const fileError = ref(null);
    const old_status=ref()
    const handleUpload = () => {
      document.getElementById("upload-image").click();
    };

    const handleOpenPdf = document => {
      window.open(document.url, "_blank");
    };

    const delete_file = async document => {
      const index = values.value.uploadedDocuments.findIndex(
        doc => (doc.filePath = document.filePath)
      );
      values.value.uploadedDocuments.splice(index, 1);
      await deleteImage(document.filePath);
    };

    const values = ref({});

    const handleChange = async e => {
      isPending.value = true;
      const types = ["image/png", "image/jpeg", "image/jpg", "application/pdf"];
      const selected = e.target.files[0];
      console.log(selected);
      if (selected && types.includes(selected.type)) {
        file.value = selected;
        fileError.value = null;
        if (values.value.uploadedDocuments.length < 3) {
          await uploadImage(file.value, "Bank-account");
          values.value.uploadedDocuments.push({
            url: url.value,
            filePath: filePath.value,
            type: selected.type
          });
        }
      } else {
        console.log("error");
        file.value = null;
        fileError.value =
          "סוג קובץ זה אינו נתמך, נא להעלות קובץ תמונה או PDF בלבד!";
      }
      isPending.value = false;
    };

    const validation = () => {
      for (let key in values.value) {
        if (key == "fullName") {
          if (values.value[key].length == 0) {
            error.value = "נא למלא שם מלא של העובד";
            return false;
          }
        }
       if (key == "idNumber") {
          if (values.value[key].length <7) {
            error.value = "נא למלא מספר זהות או דרכון תקין";
            return false;
          }
        }
        if (key == "ownerName") {
          if (values.value[key].length < 1) {
            error.value = "נא למלא את שם פותח הבקשה";
            return false;
          }
        }
        if (key == "email") {
          if (
            values.value[key].length == 0 ||
            !values.value[key].includes("@")
          ) {
            error.value = "נא למלא כתובת מייל תקינה";
            return false;
          }
        }
        if (key == "bankName") {
          if (values.value[key].length == 0) {
            error.value = "נא למלא את שם הבנק";
            return false;
          }
        }
        if (key == "brancheBankNumber") {
          if (values.value[key] == 0) {
            error.value = "נא למלא את מספר סניף הבנק";
            return false;
          }
        }
        if (key == "bankAccount") {
          if (values.value[key] == 0) {
            error.value = "נא למלא את מספר חשבון הבנק";
            return false;
          }
        }
      }
      return true;
    };

    const handleSubmit = async () => {
      if (!validation()) {
        swal("בעיה", `${error.value}`, "warning");
      } else {
        delete values.value.createdAt
        const request_collection = convert_type_he_to_en(values.value.type)
        console.log(request_collection);
        console.log(props.docId);
        await projectFirestore.collection('Applications').doc(props.docId).
        collection(request_collection).doc(values.value.id).set(values.value,{merge:true})
        if(old_status.value!=values.value.status){
          await update_status_counter(old_status.value,values.value.status,props.docId)
        }
        sendEmailToUser();
        swal("הצלחה", "הטופס עודכן בהצלחה", "success");
        router.go(-1)
      }
    };
    function sendEmailToUser() {
      projectFunctions.httpsCallable('send_email')({
        html: `<div>
                <h3>
                    שלום ${values.value.ownerName},
                </h3>
                <h3>
                    בקשתך המשרדית לעובד: ${values.value.fullName}
                </h3>
                <h3>
                    עבור: ${values.value.type}
                </h3>
                <h3>
                    לסניף/אתר ${values.value.branche}
                </h3>
                <h3>
                    בסטטוס ${values.value.status}
                </h3>
                <h3>
                    הערות: ${values.value.msg}
                </h3>
                <h3 style="color:red;">
                    נא לשים לב להמשך עדכונים שיתקבלו אוטמטית מהמערכת בכל שלבי הטיפול!
                </h3>
            </div>`,
        destinations:[`${values.value.email}`],
        from:"מערכת לבקשות משרדיות",
        title:"עדכון סטטוס בקשה משרדית"
      }) 
    }
    onMounted(() => {
       isPending.value = true
      setTimeout(() => {
        if(props.data){
          values.value=props.data
          old_status.value = values.value.status
           isPending.value = false
        }
      }, 1000);
    });

    return {
      values,
      error,
      handleSubmit,
      handleChange,
      file,
      fileError,
      handleUpload,
      isPending,
      handleOpenPdf,
      delete_file,
      banksOptions,
      chargeOptions,
      statusOptions
    };
  }
};
</script>

<style scoped>
.container {
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 3px;
}
.warpper {
  width: 50%;
  height: calc(100% - 100px);
  border: 1px solid black;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}
.field {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 5px;
  margin-top: 15px;
}
.images {
  width: 100%;
  height: max-content;
  max-height: max-content;
  min-height: 100px;
  padding: 3px;
  display: flex;
  margin-top: 5px;
  overflow: hidden;
}
.images .image {
  position: relative;
  width: calc(100% / 3);
  height: 100%;
  cursor: pointer;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-left: 5px;
}

.images .image .delete-bar {
  margin-top: 10px;
  width: 100%;
  height: 30px;
  background-color: rgb(145, 3, 3);
  color: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  border-radius: 5px;
}

.images .image img {
  max-width: 100%;
  max-height: calc(100% - 40px);
  border-radius: 5px;
}
.field p {
  font-size: 1.1rem;
}
.send-btn {
  margin-top: 5px;
  width: 50%;
}
input[type="date"] {
  height: 35px;
  border: 0.5px solid lightgrey;
  border-radius: 3px;
}
input[type="date"]:hover {
  border: 1.1px solid lightblue;
}
input[type="date"]:focus {
  outline: none;
  border: 1.1px solid lightblue;
}
input[type="time"] {
  height: 35px;
  border: 0.5px solid lightgrey;
  border-radius: 3px;
}
input[type="time"]:hover {
  border: 1.1px solid lightblue;
}
input[type="time"]:focus {
  outline: none;
  border: 1.1px solid lightblue;
}
.upload-btn {
  width: 30%;
}

@media screen and (max-width: 600px) {
 .container {
    justify-content: unset;
    overflow: hidden;
    background: var(--secondary);
    color: #fff;
  }
  .warpper {
    width: 100%;
    border: none;
    overflow-y: auto;
    flex-shrink: 0;
  }
  .send-btn {
    width: 100%;
  }
  .upload-btn {
    width: 100%;
  }
}
</style>
